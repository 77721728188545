import {
  ISiteGalleryItem,
  IPendingGalleryItemItem
} from '../../../../../../interfaces';
import { getLogger } from 'aurelia-logging';
import { GalleryService } from '../../../../../../services';
import {
  ensureNumber,
  getNewId,
  isMobileWatcher
} from '../../../../../../utility';
import './sitedetailsgallerylist.css';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../../../common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import {
  SiteDetailsGalleryListQuery,
  SiteDetailsGalleryListQueryVariables
} from '../../../../../../../custom_typings/graphql';
import { SiteDetailsGalleryDetailsQueryFragmentDefinition } from '../sitedetailsgallerydetails/sitedetailsgallerydetails';

@autoinject()
export class SiteDetailsGalleryList extends GraphQLBaseViewModel<
  void,
  SiteDetailsGalleryListQuery,
  SiteDetailsGalleryListQueryVariables
> {
  constructor(private galleryService: GalleryService, private router: Router) {
    super(getLogger(SiteDetailsGalleryList.name));
    this.unsubscribeOnDetach(
      isMobileWatcher(isMobile => (this.isMobile = isMobile))
    );
  }

  query = gql`
    ${SiteDetailsGalleryDetailsQueryFragmentDefinition}
    query SiteDetailsGalleryListQuery($siteId: Int!) {
      site(siteId: $siteId) {
        siteId
        galleryItems {
          ...SiteDetailsGalleryDetailsQueryFragment
          siteGalleryItemId
          tags
          thumbnailUrl
        }
      }
    }
  `;

  uploadError: boolean = false;
  isMobile: boolean;
  imageFilter = '';
  siteId: number;
  @computedFrom('data.site.galleryItems', 'imageFilter')
  get filteredGalleryItems() {
    if (!this.data || !this.data.site || !this.data.site.galleryItems)
      return [];

    const { galleryItems } = this.data.site;
    const { imageFilter } = this;
    if (!imageFilter) return galleryItems;
    const preppedFilter = imageFilter.trim().toLocaleLowerCase();
    if (!preppedFilter.length) return galleryItems;

    return galleryItems.filter(
      g =>
        g.tags &&
        g.tags.some(tag =>
          tag
            .trim()
            .toLocaleLowerCase()
            .includes(preppedFilter)
        )
    );
  }

  @computedFrom('data.site.galleryItems')
  get hasGalleryItems() {
    return (
      this.data &&
      this.data.site &&
      this.data.site.galleryItems &&
      this.data.site.galleryItems.length
    );
  }

  activate(params: { id: string }) {
    const siteId = ensureNumber(params.id);
    this.siteId = siteId;
    this.variables = {
      siteId
    };
  }

  galleryItemsPendingUpload: IPendingGalleryItemItem[] = [];
  public async uploadImage(files: File[] | FileList) {
    if (files.length === 0) return;

    const tasks = [];
    for (let i = 0; i < files.length; i++) {
      const fileToUpload = files[i];
      if (fileToUpload.type.indexOf('image/') === -1) continue;
      tasks.push(this.uploadSingleImage(fileToUpload));
    }

    await Promise.all(tasks);
    await this.revalidateAllActiveQueries();
  }

  private async uploadSingleImage(fileToUpload: File) {
    this.uploadError = false;
    const id = getNewId();
    this.galleryItemsPendingUpload.push({
      siteId: this.siteId,
      internalId: id
    });
    try {
      await this.galleryService.uploadGalleryImageForSite(
        this.siteId,
        fileToUpload
      );
    }
    catch (ex) {      
      this.uploadError = true;
    }

    this.galleryItemsPendingUpload = this.galleryItemsPendingUpload.filter(
      p => p.internalId !== id
    );
  }

  public viewImageDetails(galleryImage: ISiteGalleryItem) {
    const shouldReplace = !this.isMobile;
    this.router.navigateToRoute(
      'sitedetails-gallerydetails',
      { galleryItemId: galleryImage.siteGalleryItemId },
      { replace: shouldReplace }
    );
  }

  dragCounter = 0;
  isDraggingOver = false;
  dragenter(event: DragEvent) {
    event.preventDefault();
    this.dragCounter++;
    if (this.dragCounter && !this.isDraggingOver) this.isDraggingOver = true;
  }
  dragover(event: DragEvent) {
    event.preventDefault();
  }

  dragleave(event: DragEvent) {
    event.preventDefault();
    this.dragCounter--;
    if (!this.dragCounter) this.isDraggingOver = false;
  }

  drop(event: DragEvent) {
    event.preventDefault();
    this.isDraggingOver = false;
    if (event.dataTransfer != null) this.uploadImage(event.dataTransfer.files);
  }
}
